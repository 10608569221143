exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-avis-js": () => import("./../../../src/pages/avis.js" /* webpackChunkName: "component---src-pages-avis-js" */),
  "component---src-pages-charte-js": () => import("./../../../src/pages/charte.js" /* webpackChunkName: "component---src-pages-charte-js" */),
  "component---src-pages-expression-corporelle-js": () => import("./../../../src/pages/expression-corporelle.js" /* webpackChunkName: "component---src-pages-expression-corporelle-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-massages-js": () => import("./../../../src/pages/massages.js" /* webpackChunkName: "component---src-pages-massages-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentionsLegales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-relaxation-js": () => import("./../../../src/pages/relaxation.js" /* webpackChunkName: "component---src-pages-relaxation-js" */),
  "component---src-pages-tarifs-js": () => import("./../../../src/pages/tarifs.js" /* webpackChunkName: "component---src-pages-tarifs-js" */)
}

